import React, { useState } from "react"
import styled from "styled-components"

import { useForm } from "react-hook-form"

import { handleContactForm } from "../services/apiRequests.js"

import Layout from "../components/layout.js"

import { OvalAnchor } from "../components/elements/buttons.js"
import ContactUsSvg from "../images/contact_us.svg"

const Container = styled.div`
  position: relative;
  z-index: 1;
  background: white;
  box-sizing: border-box;
  overflow: auto;
  height: 100%;
  width: auto;
  margin: auto;
  padding-top: 24px;
`
const Content = styled.section`
  padding: 0 24px;
  overflow: hidden;
  width: inherit;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 1em 0;
`
const InputWrapper = styled.div`
  position: relative;
  margin-top: 0.25rem;
  height: 100%;
  min-width: 250px;
  max-width: 700px;
`
const Label = styled.label`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 2em;
`
const Input = styled.input`
  position: relative;
  z-index: 1;
  display: block;
  border: 1px solid rgb(91, 105, 135);
  color: rgb(3, 27, 78);
  width: 100%;
  height: 48px;
  padding: 0 16px;
  background-color: #fff;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 3px;
  font-size: 16px;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-align: left;
  overflow:
  &:active {
    border: 2px dotted rgb(91, 105, 135);

  }

`
const TextboxInput = styled(Input).attrs({
  as: "textarea",
})`
  padding: 7px 16px 7px 16px;
  height: 300px;
  font-family: inherit;
  line-height: 1.5em;
`
const Err = styled.span`
  color: #bf1650;
  font-size: 0.8em;
  &::before {
    display: inline;
    content: "⚠ ";
  }
`
const LinkButton = styled(OvalAnchor).attrs({
  as: "button",
})`
  outline: none;
  border: none;
  padding: 1em 2em;
  &::placeholder {
    font: inherit;
  }
`

const ContactForm = () => {
  const { register, handleSubmit, errors } = useForm()
  const [isSent, setSent] = useState(false)
  // const { from } = location.state || { from: { pathname: "/" } }

  const onSubmit = data => {
    handleContactForm(data, (err, success) => {
      if (err) {
        alert("Uh oh! Looks like that didn't go through. Please try again.")
      } else {
        setSent(true)
      }
    })
  }

  return (
    <Container>
      <Content>
        {isSent ? (
          <div style={{ margin: "auto" }}>
            <h3>Message sent!</h3>
          </div>
        ) : (
          <>
            <h4 style={{ fontSize: "1.125rem" }}>Contact Us</h4>
            <p></p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputWrapper>
                <Label htmlFor="name">Full Name</Label>
                <Input
                  type="text"
                  name="name"
                  ref={register({ required: true })}
                  placeholder="John Smith"
                  style={{ backgroundColor: errors.name ? "pink" : "white" }}
                />
                {errors.name && <Err>Please enter your name.</Err>}
              </InputWrapper>
              <InputWrapper>
                <Label htmlFor="email">Email</Label>
                <Input
                  type="text"
                  name="email"
                  placeholder="john.smith@gmail.com"
                  ref={register({ required: true, pattern: /^\S+@\S+$/i })}
                  style={{ backgroundColor: errors.email ? "pink" : "white" }}
                />
                {errors.email && (
                  <Err>Gah! This is an invalid email address.</Err>
                )}
              </InputWrapper>
              <InputWrapper>
                <Label htmlFor="phoneNumber">Phone</Label>
                <Input
                  type="tel"
                  name="phoneNumber"
                  placeholder="+1 647 123 4567"
                  ref={register({ required: true })}
                  style={{
                    backgroundColor: errors.phoneNumber ? "pink" : "white",
                  }}
                />
                {errors.phoneNumber && <Err>We need a way to contact you.</Err>}
              </InputWrapper>
              <InputWrapper>
                <Label htmlFor="service">Service</Label>
                <Input
                  type="text"
                  name="service"
                  placeholder="e.g. MCAT or Interview Prep."
                  ref={register({ required: true })}
                  style={{ backgroundColor: errors.service ? "pink" : "white" }}
                />
                {errors.service && (
                  <Err>What service would you like to talk about?</Err>
                )}
              </InputWrapper>
              <InputWrapper>
                <Label htmlFor="body">Message</Label>
                <TextboxInput
                  type="text"
                  name="body"
                  ref={register({ required: true })}
                  placeholder={
                    "Tell us what you'd like help with, when you'd like it, and what you're looking for..."
                  }
                  style={{ backgroundColor: errors.body ? "pink" : "white" }}
                />
                {errors.body && <Err>This field is empty</Err>}
              </InputWrapper>
              <ButtonWrapper>
                <LinkButton type="submit" backgroundColor={"#F50057"}>
                  Send
                </LinkButton>
              </ButtonWrapper>
            </form>
          </>
        )}
      </Content>
    </Container>
  )
}

const Wrapper = styled.div`
  width: 100vw;
  min-height: 120vh;
`
const SvgWrapper = styled.div`
  @media (min-width: 100px) {
    visibility: hidden;
  }
  @media (min-width: 700px) {
    visibility: visible;
  }
  width: 100%;
  padding: 10%;
  background-color: rgb(250, 250, 250);
  align-items: stretch;
  padding-bottom: 100px;
`
const FormWrapper = styled.div`
  padding: 50px;
  z-index: 2;
  position: absolute;
  top: -5px;
  width: 40%;
  transform: scale(0.9);
`
const Img = styled.img`
  display: block;
  width: 80%;
  height: auto;
  margin: auto;
  opacity: 0.2;
`

const Contact = () => {
  return (
    <Layout>
      <Wrapper>
        <FormWrapper>
          <ContactForm />
        </FormWrapper>
        <SvgWrapper>
          <Img src={ContactUsSvg} />
        </SvgWrapper>
      </Wrapper>
    </Layout>
  )
}

export default Contact
